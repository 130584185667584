import React from 'react';
import {
  StyleSheet,
  ScrollView,
  Text,
} from 'react-native';
import { isEmpty } from 'ramda';
import { Button } from '../ui';
import SyncItem from '../components/SyncItem';
import SyncIndicator from '../components/SyncIndicator';
import messages from '../data/messages.json';

const styles = StyleSheet.create({
  view: {
    flex: 1,
    marginTop: 26,
    padding: 8,
  },
  tabBar: {
    backgroundColor: '#ddd',
  },
  tabBarLabel: {
    color: '#444',
  },
});

const Sync = ({ useSync }) => {
  const {
    url,
    updateUrl,
    isUrlValid,
    syncValue,
    items,
    deleteItem,
    refresh,
    sync,
    isSyncing,
    synced,
    syncedTotal,
  } = useSync;

  const onDelete = async (id) => {
    await deleteItem(id);
    refresh();
  };

  return (
    <ScrollView style={styles.view}>
      <Text style={{ textAlign: 'center' }}>URL de synchronisation</Text>
      <input
        type='text'
        placeholder={messages.sync_url_input}
        value={url}
        onChangeText={updateUrl}
      />
      {items.map((item) => <SyncItem item={item} key={item.id} deleteItem={onDelete} />)}
      {isSyncing && <SyncIndicator synced={synced} syncedTotal={syncedTotal} />}
      {isUrlValid()
        ? (
          <Button
            text="Synchroniser"
            disabled={isEmpty(items) || isSyncing}
            style={{ margin: 20 }}
            onPress={sync}
          />
        )
        : <Text style={{ textAlign: 'center', margin: 20 }}>Veuillez entrer une url valide</Text>}
    </ScrollView>
  );
};

export default Sync;
