import React, { useState, useEffect } from 'react';
import { View, StyleSheet } from 'react-native';
import { TabView, TabBar } from 'react-native-tab-view';
import { isEmpty } from 'ramda';
import Photo from '../components/Photo';
import Mode from '../components/Mode';
import useSession from '../hooks/useSession';
import Products from '../components/Products';
import SelectedProduct from '../components/SelectedProduct';

const styles = StyleSheet.create({
  view: {
    flex: 1,
    marginTop: 26,
    padding: 8,
  },
  tabBar: {
    backgroundColor: '#ddd',
  },
  tabBarLabel: {
    color: '#444',
  },
});

const initRoutes = (currentMode) => (currentMode === 'production'
  ? [
    { key: 'search', title: 'Produit' },
    { key: 'selected', title: 'Conditionnement' },
  ]
  : [
    { key: 'search', title: 'Produit' },
  ]);

const Main = ({
  cameraIsGranted,
  useSync,
}) => {
  const {
    mode,
    changeMode,
    product,
    chooseProduct,
    removeProduct,
    search,
    image,
    setImage,
    save,
    saving,
    conditionning,
    chooseConditionning,
    removeConditionning,
  } = useSession();
  const { refresh } = useSync;
  const [index, setIndex] = useState(0);
  const [routes, setRoutes] = useState(initRoutes(mode));

  useEffect(() => {
    setRoutes(initRoutes(mode));
  },
    [mode, setRoutes, initRoutes]);

  const renderScene = ({ route }) => {
    switch (route.key) {
      case 'search':
        return (
          <Products
            search={search}
            chooseProduct={chooseProduct}
            removeProduct={removeProduct}
            selectedProduct={product}
            product={product}
          />
        );
      case 'selected':
        return (
          <SelectedProduct
            selectedProduct={product}
            conditionning={conditionning}
            chooseConditionning={chooseConditionning}
            removeConditionning={removeConditionning}
          />
        );
      default: return null;
    }
  };
  const trySaving = async () => {
    await save();
    refresh();
  };

  const renderTabBar = (props) => (
    <TabBar
      {...props}
      indicatorStyle={{ backgroundColor: '#aaa' }}
      labelStyle={styles.tabBarLabel}
      style={styles.tabBar}
    />
  );

  return (
    <View style={styles.view}>
      <Mode mode={mode} changeMode={changeMode} />
      <TabView
        tabBarPosition="top"
        navigationState={{ index, routes }}
        renderScene={renderScene}
        renderTabBar={renderTabBar}
        onIndexChange={setIndex}
        style={{ backgroundColor: '#b7b7b7', marginBottom: 8 }}
      />
      <Photo
        disabled={isEmpty(product)}
        image={image}
        setImage={setImage}
        onSave={trySaving}
        isSaving={saving}
        cameraIsGranted={cameraIsGranted}
      />
    </View>
  );
};

export default Main;
