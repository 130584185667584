import { useState } from 'react';
import {
  and, isEmpty, isNil, append, propOr,
} from 'ramda';

import { v4 as uuidv4 } from 'uuid';
import useImage from './useImage';
import useMode from './useMode';
import useProducts from './useProducts';
import messages from '../data/messages.json';

const useSession = () => {
  const { image, setImage } = useImage();
  const { mode, changeMode } = useMode();
  const {
    productsList,
    product,
    chooseProduct,
    removeProduct,
    search,
    conditionning,
    chooseConditionning,
    removeConditionning,
  } = useProducts();
  const [saving, setSaving] = useState(false);
  const canSave = () => and(
    !isEmpty(product),
    and(
      !isNil(mode),
      !isNil(image),
    ),
  );

  const reset = () => {
    setImage(null);
    removeProduct();
    removeConditionning();
    search('');
  };

  const save = async () => {
    setSaving(true);
    const id = uuidv4();
    const items = JSON.parse(window.localStorage.getItem('syncList'));
    if (canSave()) {
      try {
        window.localStorage.setItem('syncList', JSON.stringify(append({
          product, mode, image, id, conditionningId: propOr(null, 'conditionningId', conditionning),
        }, items)));
        reset();
      } catch (error) {
        setSaving(false);
        return new Error(messages.could_not_save);
      }
    }
    setSaving(false);
    return messages.could_not_save;
  };

  return {
    mode,
    changeMode,
    productsList,
    product,
    chooseProduct,
    removeProduct,
    search,
    image,
    setImage,
    canSave,
    save,
    saving,
    conditionning,
    chooseConditionning,
    removeConditionning,
  };
};

export default useSession;
