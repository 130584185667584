/* eslint-disable no-underscore-dangle */
import { useState } from 'react';
import {
  filter, ifElse, always, or, gte, length, toLower, includes, __,
} from 'ramda';
import data from '../data/products.json';

const fields = ['productId', 'speciesId', 'typeId'];

const chooseProductOrFail = (product, setProduct) => {
  const isValid = () => fields.reduce(
    (acc, field) => (!acc ? acc : product[field] && product[field].match(/^[a-f\d]{24}$/i)),
    true,
  );

  return ifElse(
    isValid,
    always(setProduct(product)),
    always(new Error('product not valid')),
  )(product);
};

const chooseConditionningOrFail = (conditionning, product, setConditionning) => {
  const isValid = includes(__, product);

  return ifElse(
    isValid,
    always(setConditionning(conditionning)),
    always(new Error('conditioning not valid')),
  )(conditionning);
};

const productsList = data
  ? data.flatMap((product) => product.species.flatMap((species) => (
    species.types.flatMap((type) => ({
      productId: product._id.$oid,
      speciesId: species._id.$oid,
      typeId: type._id.$oid,
      kind: product.kind,
      species: species.species,
      type: type.type,
      name: `${product.kind} ${species.species} ${type.type}`,
      conditionnings: type.conditionnings.map((conditionning) => ({
        name: conditionning.conditionning,
        conditionningId: conditionning._id.$oid,
      })),
    }))
  )))
  : [];

const matchString = (s) => (item) => or(
  includes(toLower(s), toLower(item.kind)),
  or(
    includes(toLower(s), toLower(item.species)),
    includes(toLower(s), toLower(item.type)),
  ),
);

const useProducts = () => {
  const [product, setProduct] = useState();
  const [conditionning, setConditionning] = useState();

  const chooseProduct = (p) => chooseProductOrFail(p, setProduct);

  const removeProduct = () => setProduct(null);

  const chooseConditionning = (c) => chooseConditionningOrFail(c, product, setConditionning);

  const removeConditionning = () => setConditionning(null);

  const search = (s) => (gte(length(s), 3)
    ? filter(matchString(s), productsList)
    : []);

  return {
    productsList,
    product,
    chooseProduct,
    removeProduct,
    conditionning,
    chooseConditionning,
    removeConditionning,
    search,
  };
};

export default useProducts;
