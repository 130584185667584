import React from 'react';
import {
  StyleSheet, View,
} from 'react-native';

import { RadioButton } from '../ui';
import messages from '../data/messages.json';

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 6,
    marginBottom: 6,
  },
});

const Mode = ({ mode, changeMode }) => (
  <View style={styles.container}>
    <RadioButton
      onPress={() => changeMode('production')}
      disabled={mode === 'production'}
      text={messages.production}
      style={{ flex: 1 }}
    />
    <RadioButton
      onPress={() => changeMode('type')}
      disabled={mode === 'type'}
      text={messages.type}
      style={{ flex: 1 }}
    />
  </View>
);

export default Mode;
