import React from 'react';
import { pathOr } from 'ramda';
import {
  Button,
} from '../ui';
import messages from '../data/messages.json';
import { Image } from 'react-native';

const SyncItem = ({ item, deleteItem }) => {
  return (<>
    <h2>{messages[pathOr('', ['mode'], item)]}</h2>
    <span style={{ textAlign: 'center' }}>{pathOr('', ['product', 'name'], item)}</span>
    <Image source={{ uri: pathOr('', ['image'], item) }} style={{ height: 300 }} />
    <Button
      text="supprimer"
      onPress={() => deleteItem(item.id)}
      style={{ backgroundColor: '#aaa', marginTop: 15 }}
    />
  </>
  )
};

SyncItem.propTypes = {
};

export default SyncItem;
