import React, { useState } from 'react';
import {
  Image, View, Text, StyleSheet,
} from 'react-native';

import { Button } from '../ui';
import messages from '../data/messages.json';
import { async } from 'q';

const styles = StyleSheet.create({
  view: {
    borderTopWidth: 2,
    borderColor: '#444',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 5,
  },
  button: {
    marginBottom: 0,
    flex: 1,
  },
  buttons: {
    marginTop: 8,
    marginBottom: 4,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  error: {
    backgroundColor: 'red',
    color: 'white',
    textAlign: 'center',
    paddingTop: 8,
    paddingBottom: 8,
    marginTop: 4,
    marginBottom: 4,
  },
});

const Photo = ({
  disabled,
  image,
  setImage,
  onSave,
  isSaving,
  cameraIsGranted,
}) => {
  const [error, setError] = useState();
  const { width, height } = { width: 960, height: 600 };

  const getBase64 = (file) => {
    return new Promise(function(resolve, reject) {
      const reader = new FileReader();
      reader.onload = function() { resolve(reader.result); };
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  }

  const handleCapture = async (target) => {
    if (target.files) {
      if (target.files.length !== 0) {
        const file = target.files[0];
        const newUrl = await getBase64(file, setImage);
        setImage(newUrl);
      }
    }
  }

  if (cameraIsGranted === null) {
    return <View />;
  }

  if (cameraIsGranted === false) {
    const msg = "L'accès à la caméra n'est pas autorisé";
    return <Text style={styles.error}>{msg}</Text>;
  }
  if (error) {
    return <Text style={styles.error}>{error.message}</Text>;
  }

  if (!image) {
    return (
      <View style={styles.buttons}>
        <input accept="image/*" id="icon-button-file" type="file" capture="environment" onChange={e => handleCapture(e.target)} />
      </View>
    );
  }

  return (
    <View style={styles.view}>
      {image && (
        <Image
          resizeMode="cover"
          source={{ uri: image }}
          style={{ width: width, height: width * (3 / 4) }}
        />
      )}
      <Text>{isSaving}</Text>
      <View style={styles.buttons}>
        <Button
          disabled={isSaving}
          text="Annuler"
          style={{ flex: 1, backgroundColor: '#999' }}
          onPress={() => setImage(null)}
        />
        <Button
          text="Enregister"
          disabled={isSaving}
          style={{ flex: 3, backgroundColor: '#999' }}
          onPress={onSave}
        />
      </View>
    </View>
  );
};

export default Photo;
