import React from 'react';
import {
  StyleSheet, TouchableOpacity, Text,
} from 'react-native';

const styles = StyleSheet.create({
  customStyle: {
  },
  button: {
    borderRadius: 4,
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: 8,
    marginRight: 8,
    height: 42,
    backgroundColor: '#52b788',
  },
  buttonEnabled: {
    opacity: 0.6,
  },
  buttonDisabled: {
    opacity: 1,
  },
  text: {
    color: 'white',
    textTransform: 'uppercase',
    fontSize: 12,
  },
});

const Button = ({
  text,
  onPress,
  disabled,
  style,
}) => (
  <TouchableOpacity
    onPress={onPress}
    style={[styles.button, disabled ? styles.buttonDisabled : styles.buttonEnabled, style]}
    disabled={disabled}
  >
    <Text style={styles.text}>{text}</Text>
  </TouchableOpacity>
);

Button.defaultProps = {
  disabled: false,
  style: undefined,
};
export default Button;
