import { AppRegistry } from 'react-native';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

AppRegistry.registerComponent('App', () => App);
AppRegistry.runApplication('App', {
  rootTag: document.getElementById('root')
});

serviceWorkerRegistration.register();
