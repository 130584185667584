/* eslint-disable no-underscore-dangle */
import React from 'react';
import {
  View,
  StyleSheet,
  Text,
  TouchableHighlight,
} from 'react-native';
import ProductItem from './ProductItem';

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 8,
  },
  selected: {
    fontWeight: 'bold',
    fontSize: 16,
    paddingBottom: 4,
  },
  remove: {
    padding: 4,
    paddingLeft: 12,
    paddingRight: 12,
    backgroundColor: '#efefef',
    fontSize: 18,
    fontWeight: 'bold',
  },
});

const SelectedProduct = ({
  selectedProduct,
  conditionning,
  chooseConditionning,
  removeConditionning,
}) => {
  if (!selectedProduct) return null;
  return (
    <View style={styles.container}>
      {conditionning
        && (
          <ProductItem
            key={`current-${conditionning.name}`}
            title={conditionning.name}
            action={() => (
              <TouchableHighlight
                onPress={() => removeConditionning(conditionning)}
              >
                <Text style={styles.remove}>-</Text>
              </TouchableHighlight>
            )}
          />
        )}
      {!conditionning && selectedProduct.conditionnings.map((item) => (
        <ProductItem
          key={`current-${item.name}`}
          title={item.name}
          action={() => (
            <TouchableHighlight
              onPress={() => chooseConditionning(item)}
            >
              <Text style={styles.remove}>+</Text>
            </TouchableHighlight>
          )}
        />
      ))}

    </View>
  );
};

export default SelectedProduct;
