import { useReducer } from 'react';
import {
  cond, always, inc, equals, T,
} from 'ramda';

const reducer = (state, action) => cond([
  [equals('increment'), always({ value: inc(state.value) })],
  [equals('reset'), always({ value: 0 })],
  [T, always(new Error('action not defined'))],
])(action.type);

const SyncedReducer = () => {
  const [state, dispatch] = useReducer(reducer, { value: 0 });
  const incSynced = () => dispatch({ type: 'increment' });
  const resetSynced = () => dispatch({ type: 'reset' });

  return {
    synced: state.value,
    incSynced,
    resetSynced,
  };
};

export default SyncedReducer;
