import { useState } from 'react';
import { includes } from 'ramda';

const modes = ['production', 'type'];
const defaultMode = 'type';

const useMode = () => {
  const [mode, setMode] = useState();
  const changeMode = (newMode) => {
    setMode(includes(newMode, modes) ? newMode : defaultMode);
  };
  return {
    mode,
    changeMode,
  };
};

export default useMode;
