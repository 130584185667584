import { useState, useEffect } from 'react';
import axios from 'axios';
import isUrl from 'is-valid-http-url';
import useSynced from './useSynced';

function addImage(name, image, url) {
  const uriParts = image.split('.');
  const fileType = uriParts[uriParts.length - 1];
  const formData = new FormData();
  formData.append('image', {
    uri: image,
    name: `${name}.${fileType}`,
    type: `image/${fileType}`,
  });
  return axios.post(`${url}/greenpic/upload`, formData);
}

const updateProduct = async (item, image, url) => axios.post(`${url}/greenpic/update`, {
  productId: item.product.productId,
  speciesId: item.product.speciesId,
  typeId: item.product.typeId,
  conditionningId: item.conditionningId,
  mode: item.mode,
  image,
});

const getSyncValue = (callback) => {
  const syncList = window.localStorage.getItem('syncList');
  const items = JSON.parse(syncList);
  callback(items !== null ? items.length : 0);
};

const getSyncItems = (callback) => {
  const r = window.localStorage.getItem('syncList');
  const items = JSON.parse(r);
  callback(items !== null ? items : []);
};

const getFormattedUrl = (url) => {
  const parsed = new URL(url);
  return `${parsed.protocol}//${parsed.hostname}`;
};

const useSync = () => {
  const syncUrl = window.localStorage.getItem('syncUrl');
  const [url, setUrl] = useState(syncUrl || 'https://');

  const [syncValue, setSyncValue] = useState(0);
  const [items, setItems] = useState([]);
  const [isSyncing, setIsSyncing] = useState(false);
  const { synced, incSynced, resetSynced } = useSynced();
  const [syncedTotal, setSyncedTotal] = useState(0);

  useEffect(() => {
    getSyncValue(setSyncValue);
    getSyncItems(setItems);
  }, [setSyncValue]);

  const isUrlValid = () => isUrl(url);

  const refreshSyncValue = () => getSyncValue(setSyncValue);

  const refreshItems = () => getSyncItems(setItems);

  const updateUrl = (newUrl) => {
    window.localStorage.setItem('syncUrl', newUrl);
    setUrl(newUrl);
  };

  const refresh = () => {
    refreshItems();
    refreshSyncValue();
  };

  const deleteItem = async (id) => {
    const current = JSON.parse(window.localStorage.getItem('syncList'));
    window.localStorage.setItem('syncList', JSON.stringify(current.filter((a) => a.id !== id)));
  };

  const sync = async () => {
    setIsSyncing(true);
    setSyncedTotal(syncValue);
    await Promise.all(items.map(async (item) => {
      const uploadResult = await addImage(item.id, item.image, getFormattedUrl(url));
      if (uploadResult.status === 200) {
        const updateResult = await updateProduct(item, uploadResult.data.url, getFormattedUrl(url));
        if (updateResult.status === 200) {
          await deleteItem(item.id);
          incSynced();
          refresh();
        }
      }
    }));
    resetSynced();
    setSyncedTotal(syncValue);
    setIsSyncing(false);
  };

  return {
    url,
    updateUrl,
    isUrlValid,
    syncValue,
    refresh,
    items,
    deleteItem,
    sync,
    isSyncing,
    synced,
    syncedTotal,
  };
};

export default useSync;
