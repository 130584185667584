import React from 'react';
import {
  ActivityIndicator, Text, View, StyleSheet,
} from 'react-native';

const styles = StyleSheet.create({
  view: {
    flex: 1,
    marginTop: 15,
    padding: 8,
    alignItems: 'center',
  },
});

const SyncIndicator = ({ synced, syncedTotal }) => (
  <View style={styles.view}>
    <Text>
      Sauvegarde en cours
    </Text>
    <Text>
      {synced}
      {' '}
      /
      {' '}
      {syncedTotal}
    </Text>
    <ActivityIndicator size={35} color="#52b788" />
  </View>
);

export default SyncIndicator;
