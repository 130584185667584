/* eslint-disable no-underscore-dangle */
import React, { useState, useCallback } from 'react';
import {
  TextInput,
  TouchableHighlight,
  Text,
  View,
  ScrollView,
  StyleSheet,
} from 'react-native';
import ProductItem from './ProductItem';
import messages from '../data/messages.json';

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 8,
  },
  input: {
    backgroundColor: 'white',
    borderTopWidth: 0,
    borderLeftWidth: 0,
    borderRightWidth: 0,
    borderBottomWidth: 1,
    borderColor: '#ccc',
    borderRadius: 4,
    fontSize: 16,
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 8,
    paddingRight: 8,
  },
  list: {
    marginTop: 8,
    flex: 1,
  },
  add: {
    padding: 4,
    paddingLeft: 12,
    paddingRight: 12,
    backgroundColor: '#efefef',
    fontSize: 18,
    fontWeight: 'bold',
  },
});

const ProductsList = ({
  chooseProduct, removeProduct, selectedProduct, search,
}) => {
  const [searchString, setSearchString] = useState('');

  const items = useCallback(() => search(searchString), [searchString]);

  return (
    <View style={styles.container}>
      {!selectedProduct
        && (
        <TextInput
          clearButtonMode="always"
          placeholder={messages.search_placeholder}
          onChangeText={(v) => setSearchString(v)}
          style={styles.input}
        />
        )}
      <ScrollView style={styles.list}>
        { selectedProduct
            && (
            <ProductItem
              key={`selectedProduct-${selectedProduct.name}`}
              title={selectedProduct.name}
              action={() => (
                <TouchableHighlight
                  onPress={() => { removeProduct(); setSearchString(''); }}
                >
                  <Text style={styles.add}>-</Text>
                </TouchableHighlight>
              )}
            />
            )}
        { !selectedProduct
          && items().map((item) => (
            <ProductItem
              key={`selectedProduct-${item.name}`}
              title={item.name}
              action={() => (
                <TouchableHighlight
                  onPress={() => chooseProduct(item)}
                >
                  <Text style={styles.add}>+</Text>
                </TouchableHighlight>
              )}
            />
          ))}
      </ScrollView>
    </View>
  );
};

export default ProductsList;
