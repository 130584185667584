import React, { useState } from 'react';
import { View, StyleSheet, Text } from 'react-native';
import { NavigationContainer } from '@react-navigation/native';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { IoMdHome } from "react-icons/io";
import { IoMdSync } from "react-icons/io";

import useSync from './hooks/useSync';
import Main from './screens/Main';
import Sync from './screens/Sync';

const R = require('ramda');

const theme = {
  colors: {
    primary: '#52b788',
  },

};

const styles = StyleSheet.create({
  container: {
    width: '100%',
    height: '100%',
  },
  navigator: {
    backgroundColor: 'white',
  },
});

const Tab = createBottomTabNavigator();

const App = () => {
  const sync = useSync();
  const [cameraIsGranted, setCameraIsGranted] = useState(true);

  if (R.isNil(cameraIsGranted)) return <View />;

  return (
    <View style={{ height: "100vh" }} >
      <NavigationContainer>
        <Tab.Navigator
          screenOptions={({ route }) => ({
            "tabBarActiveTintColor": "#444",
            "tabBarInactiveTintColor": "#aaa",
            "tabBarShowLabel": true,
            "tabBarLabelStyle": {
              "fontSize": 18
            },
            "tabBarLabelPosition": "below-icon",
            "tabBarStyle": [
              {
                "display": "flex"
              },
              null
            ],
            tabBarIcon: ({ focused, color, size }) => {
              if (route.name === 'Photos') {
                return (
                  <IoMdHome />
                );
              }
              return <IoMdSync />

            },
          })}
        >
          <Tab.Screen name="Photos" component={() => <Main useSync={sync} />} />
          <Tab.Screen name="Synchronisation" component={() => <Sync useSync={sync} />} />
        </Tab.Navigator>
      </NavigationContainer >
    </View >
  );
}
export default App;

