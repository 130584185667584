import React from 'react';
import {
  StyleSheet, View, Text,
} from 'react-native';


const styles = StyleSheet.create({
  view: {
    width: '100%',
    padding: 8,
    backgroundColor: '#e3e3e3',
    marginTop: 2,
    marginBottom: 2,
    borderRadius: 4,
    flexDirection: 'row',
    alignItems: 'center',
  },
  text: {
    flex: 1,
  },
});

const ProductItem = ({
  title,
  onPress,
  action,
}) => (
  <View style={styles.view}>
    <Text style={styles.text}>{title}</Text>
    <View>
      {action()}
    </View>
  </View>
);

export default ProductItem;
